import React, { useMemo } from "react";
import Layout from "../components/layout";
import useAllProducts from "../hooks/useAllProduct";
import ProductTemplate from "../templates/product";
import Measure from "../components/sections/measure";
import ServiceAreaForm from "../components/partials/service-areas";
import RecommendedProducts from "../components/partials/recommended-products";

const LaddersProduct = () => {
  const products = useAllProducts();
  const ladders = useMemo(() => {
    if (!products) return [];

    return products
      .filter(({ node }) => node.product.sku === "window-well-ladder")
      .map(({ node }) => {
        return {
          ...node,
        };
      });
  }, [products]);

  if (!ladders.length) {
    return <div>No Products</div>;
  }

  return (
    <Layout>
      <ProductTemplate products={ladders} />
      <div className="container lg:my-24">
        <RecommendedProducts products={products} />
        <Measure product={ladders[0].product} />
        <div className="max-w-md">
          <ServiceAreaForm />
        </div>
      </div>
    </Layout>
  );
};
export default LaddersProduct;
